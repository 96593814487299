@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: grid;

  @include from(3) {
    grid-template-columns: repeat(2, 50%);
  }
}
