@import 'breakpoints.scss';
@import 'colors.scss';

$cardWidth: 277px;

.container {
  display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  flex-flow: column wrap;
  align-items: center;
  width: 100%;
  max-width: $cardWidth;
  padding: 20px 30px 10px;
  background: $white;

  @include from(2) {
    flex: 0 0 $cardWidth;
  }
}

.image {
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}

.title {
  color: themeColor('waybetter', 'secondary');
  font-size: 21px;
  font-weight: 600;
  text-align: center;
}

.description {
  width: 100%;
  text-align: center;
  white-space: pre-line;
}

.action {
  margin-top: auto;
}
