@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';
@import 'sizes.scss';

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.backgroundImageContainer {
  width: 100vw;

  @include to(3) {
    height: calc(100vh - #{$headerHeightResponsive});
    max-height: 700px;
  }
}

.content {
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  align-items: center;

  @include to(3) {
    width: 100%;
    height: 100%;
    padding: 20px 0 60px;
    background-repeat: no-repeat;
    background-position: top left 0;
    background-size: contain;
  }

  @include from(4) {
    padding-top: 48px;
  }
}

.header {
  margin-bottom: 16px;
  color: $grey850;
  font-size: 30px;
  font-weight: 700;
  text-align: center;

  @include to(2) {
    width: 80%;
    line-height: 1em;
    text-align: left;
  }

  @include from(4) {
    font-size: 50px;
  }
}

.gameTitle {
  color: $coral;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2em;

  @include to(3) {
    text-align: center;
  }

  @include from(4) {
    font-size: 32px;
  }
}

.body {
  @include markdownRemoveMargins;

  width: 80%;
  color: $grey850;
  font-size: 15px;
  text-align: center;

  @include to(2) {
    text-align: left;
  }

  @include from(4) {
    width: 60%;
    margin-bottom: 40px;
    font-size: 21px;
  }

  ul {
    display: inline-block;
    margin-left: 0;
    text-align: left;
  }

  b {
    font-weight: 600;
  }
}

.bodyWithGame {
  @include markdownRemoveMargins;

  @include from(4) {
    margin-bottom: 40px;
  }
}

.bodyFootnote {
  margin-bottom: 8px;
  color: $white;
  font-size: 15px;
  text-align: center;

  @include from(4) {
    display: none;
  }
}

.paymentPlansContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include from(4) {
    // flex-direction: row;
    // flex-wrap: wrap;
    flex-flow: row wrap;
    justify-content: center;
    margin: 16px 0;
  }
}

.ctaFootnote {
  margin-top: 8px;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
}

.disclosure {
  color: $grey300;
  font-size: 12px;
}
