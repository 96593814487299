@import 'breakpoints.scss';
@import 'colors.scss';

.container.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: themeColor('landing', 'primary');

  @include from(3) {
    padding: 75px 0;
  }

  @include to(2) {
    padding: 55px 0;
  }

  // stylelint-disable-next-line selector-class-pattern
  :global .slider-slide:focus {
    outline: none;
  }
}

.heading {
  color: $white;
  font-size: 26px;
  text-align: center;

  @include from(3) {
    margin: 0 0 65px;
  }

  @include to(2) {
    margin: 0 0 45px;
    padding: 0 30px;
    font-size: 22px;
    font-weight: 500;
  }
}

.appStoreRatings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 35px;

  @include from(3) {
    width: 60%;
    max-width: 500px;
  }

  @include to(2) {
    width: 100%;
  }
}

.review {
  margin: 0 auto;
}

.dots {
  @include from(3) {
    margin: 60px 20px 0;
  }

  @include to(2) {
    margin: 30px 20px 0;
  }
}
