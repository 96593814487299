@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
  margin-bottom: 25px;

  @include to(3) {
    display: none;
  }
}

.image {
  display: block;
  width: 70px;
  height: 70px;
  object-fit: cover;
  overflow: hidden;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  border-color: $white;
}

.titleAndDates {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.dates {
  color: $white;
  font-size: 21px;
  font-weight: 600;
}
