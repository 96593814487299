@import 'breakpoints.scss';

.steps {
  display: flex;
  justify-content: space-evenly;

  @include from(3) {
    margin-bottom: 71px;
    padding: 0 16px;
  }

  @include to(2) {
    margin-bottom: 40px;
    padding: 0 8.5px;
  }
}
