@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'colors.scss';

.row {
  display: none;
  position: relative;
}

.hero {
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.backgroundWidthConstraintSite {
  @include from(3) {
    max-width: $heroBackgroundWidth;
  }
}

.portraitImage,
.landscapeImage {
  width: 100%;
  height: 100%;
  object-position: center top;
  object-fit: cover;
}

.darken {
  filter: brightness(60%);
}

.container {
  display: flex;
  z-index: 1;
  min-height: 500px;

  @include to(2) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 110px;
    padding: 30px 20px;
  }

  @include from(3) {
    margin-top: 50px;
    padding: 0;
  }
}

.containerLeftAlign {
  text-align: left;
}

.containerCenter {
  align-items: center;
  text-align: center;

  @include to(2) {
    max-width: 350px;
  }
}

.containerCenterMobile {
  @include to(1) {
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
  }
}

.imageContainer {
  align-self: center;

  @include to(2) {
    display: flex;
    justify-content: center;
    width: 100vw;
  }

  @include from(3) {
    margin-right: 50px;
  }
}

@each $theme, $themeColors in $themes {
  .#{$theme} .imageContainer {
    $secondary: map-get($themeColors, 'secondary');

    @include to(2) {
      background: linear-gradient(
        -5deg,
        transparent 28%,
        $secondary 28%,
        $secondary 78%,
        transparent 78%
      );
    }
  }
}

.image {
  @include from(3) {
    width: 280px;
  }

  @include to(2) {
    max-height: 500px;
    margin-bottom: 42px;
  }
}

.content {
  display: flex;
  z-index: 1;
  flex-direction: column;
  color: $white;

  @include from(3) {
    flex: 2 0;
    align-self: flex-end;
  }
}

.lightBackground .content {
  color: #2b2529;
}

.headline {
  max-width: 700px;
  font-size: 46px;
  font-weight: 600;
  white-space: pre-line;

  @include to(1) {
    line-height: 1em;
  }

  @include from(2) {
    line-height: 1.1em;
  }
}

.headlineSmall {
  font-size: 34px;

  @include from(2) {
    font-size: 50px;
    font-weight: 700;
    line-height: 62px;
  }
}

.headlineLarge {
  @include from(3) {
    font-size: 80px;
  }
}

.subline {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.7px;
  line-height: 27px;

  @include from(2) {
    max-width: 480px;
    margin-bottom: 18px;
    font-size: 22px;
    line-height: 30px;
  }

  @include from(3) {
    margin-bottom: 35px;
  }
}

.testimonial {
  order: -1;
  color: $white;

  @include from(3) {
    display: none;
  }
}

.all {
  display: block;
}

.small {
  @include to(2) {
    display: block;
  }
}

.medium {
  @include between(2, 3) {
    display: block;
  }
}

.large {
  @include from(3) {
    display: block;
  }
}

.callToAction {
  @include from(3) {
    margin-bottom: 125px;
  }

  @include to(2) {
    margin: 50px auto;
  }
}

.themeMyPlan {
  @include to(1) {
    .headline {
      max-width: 12ch;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @include to(2) {
    .container.container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100vh - var(--header-height));

      @supports (min-height: 100dvh) {
        min-height: calc(100dvh - var(--header-height));
        transition: 1s min-height;
      }

      margin-top: 25px;
      padding-bottom: 260px;
    }

    .callToAction {
      margin: 15px 0;
    }
  }

  .callToAction > * {
    letter-spacing: 0.5px;
  }

  .container {
    padding: 30px 17px;
  }

  .hero {
    background-color: rgb(210 220 255);
  }

  .portraitImage {
    object-position: center bottom;
  }

  .subline {
    font-weight: 400;
  }
}
