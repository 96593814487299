.listSymbol {
  margin-right: 0.5em;
}

.valueProps {
  max-width: 276px;
  margin-left: 36px;
}

.valueProp {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
