@import 'utils.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  background: $white;
  color: $fontColor;
  font-size: 18px;
}

.image {
  width: 125px;
  height: 125px;
  margin-bottom: 25px;
}

.name {
  font-size: 21px;
}

.location {
  margin-bottom: 35px;
  color: $fontMedium;
}

.quote {
  @include markdownRemoveMargins;
  @include quotations;

  width: 100%;
  margin-bottom: 25px;
}

.link {
  align-self: flex-start;
  margin-top: auto;
}

.fact {
  margin-top: auto;
  padding-top: 25px;
}
