@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  margin: 0 24px;
  background-color: $coralLight;

  @include from(3) {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    min-height: 285px;
    padding: 48px 24px;
  }

  @include to(2) {
    display: none;
  }
}

.paymentPlanLabel {
  p {
    margin: 0 auto 10px;
    color: $white;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: center;
  }
}

.cta.cta {
  width: 330px;
  margin-top: 10px;
  background-color: $white;
  color: $grey850;

  &:hover {
    background-color: $grey850;
    color: $white;
  }
}

.footnoteStyle {
  p,
  span {
    color: $white;
    font-size: 18px;
  }
}
