@import 'sizes.scss';
@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  padding: 40px 0;
}

.heading {
  @include to(2) {
    display: none;
  }
}

.games {
  display: flex;
  text-align: center;

  @include from(3) {
    flex-direction: row;
    justify-content: space-evenly;
    max-width: $maxWidth;
  }

  @include to(2) {
    flex-direction: column;
    align-items: center;
  }
}

.game:first-of-type {
  order: -1;
}

.image {
  object-fit: cover;
  object-position: center top;

  @include from(3) {
    display: none;
  }

  @include to(2) {
    // constant derrived with 264/375 = 0.704 (height/width from mock)
    $ratio: 0.704;
    $maxWidth: 500px;

    width: 100vw;
    max-width: 500px;
    height: calc(100vw * #{$ratio});
    max-height: $maxWidth * $ratio;
    margin: 0 0 40px;
  }
}
