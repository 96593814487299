@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  padding: 60px 30px;
  background: $white;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;
}

.logos {
  @include to(1) {
    > * {
      margin-bottom: 16px;
    }
  }

  @include from(2) {
    > * {
      margin: 0 10px 30px;
    }
  }
}

.title {
  margin-bottom: 60px;
  font-size: 38px;
  font-weight: 400;
  text-align: center;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @include from(2) {
    flex: 0 0 140px;
  }
}

.image {
  margin-bottom: 16px;
  object-fit: contain;
  object-position: center;

  @include to(1) {
    height: 50px;

    @supports (object-fit: cover) {
      width: 130px;
    }
  }

  @include from(2) {
    height: 38px;

    @supports (object-fit: cover) {
      width: 100px;
    }
  }
}

.name {
  color: $fontMedium;
  text-align: center;
  white-space: normal;
}
