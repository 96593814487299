@import 'breakpoints.scss';
@import 'colors.scss';

.container.container {
  margin: 16px 24px 0;

  @include to(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include from(3) {
    display: none;
  }
}

.containerFirst {
  margin-top: 0;
}

.cta.cta {
  width: 330px;
  margin-top: 24px;
  background-color: $coralLight;
  color: $white;

  &:hover {
    background-color: $white;
    color: $coralLight;
  }
}

.paymentPlanLabel {
  margin-top: 24px;

  p {
    margin: 0;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    text-align: center;
  }
}

.footnoteStyle {
  margin-top: 9px;

  p,
  span {
    margin: 0;
    color: $white;
    font-size: 14px;
  }
}
