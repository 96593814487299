@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  margin: 0 24px;

  @include from(4) {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    max-width: 450px;
    min-height: 285px;
    padding: 48px 16px;
  }

  @include to(3) {
    display: none;
  }
}

.containerPrimary {
  background-color: $purple;
}

.containerSecondary {
  background-color: $purple;
}

.paymentPlanLabel {
  margin-bottom: 10px;
  color: $white;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2em;
  text-align: center;
}

.cta.cta {
  width: 330px;
  margin-top: 10px;
  background-color: $white;
  color: $grey850;

  &:hover {
    background-color: $grey850;
    color: $white;
  }
}
