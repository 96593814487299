@import 'breakpoints.scss';

.container {
  margin-top: 15px;

  @include to(2) {
    padding: 0 30px;
  }
}

.heading {
  margin-top: 45px;
}

.basic {
  display: flex;
  align-items: center;
  margin: 1em 0;
}

.avatar {
  margin-right: 20px;
}

.emailLogin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
