@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  @include from(3) {
    padding: 100px 20px;
  }

  @include to(2) {
    padding: 40px 0;
  }
}

.light {
  background-color: $white;
  color: $fontColor;
}

.waybetterLight {
  background-color: $magnolia;
  color: $fontColor;
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include to(2) {
    display: none;
  }
}

.sliderContainer {
  width: 100%;

  @include from(3) {
    display: none;
  }
}

.dots {
  margin: 20px 0;
}
