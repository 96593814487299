@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  border-radius: 10px;
  background-color: $white;

  @include from(3) {
    width: 550px;
    padding: 40px;
  }

  @include to(2) {
    max-width: 325px;
    padding: 30px;
  }
}

.heading {
  color: $fontColorLight;
  font-size: 42px;
}

.quote {
  text-align: center;

  @include from(3) {
    margin: 0 0 45px;
    font-size: 19px;
  }

  @include to(2) {
    margin: 0 0 25px;
    font-size: 14px;
  }
}

.reviewer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
}

.image {
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;

  @include from(3) {
    width: 65px;
    height: 65px;
    margin-right: 30px;
  }

  @include to(2) {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
