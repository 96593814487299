@import 'breakpoints.scss';
@import 'utils.scss';

.heading {
  margin-bottom: 1.5em;
  font-size: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include at(1) {
    padding: 25px;
  }

  @include from(2) {
    margin: 2em 1em auto;
  }
}

.scrollContainer {
  @include at(1) {
    width: 90%;
    margin: 0 2em auto;
  }

  @include from(2) {
    margin: 0 2em auto;
    text-align: center;
  }
}

.link {
  display: block;
  font-weight: 600;

  @include at(1) {
    margin: 10px 0;
    font-size: 14px;
  }

  @include from(2) {
    margin: 20px 0;
    font-size: 16px;
  }
}
