@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 142px;
  color: $grey800;

  @include from(3) {
    margin: 0 20.5px;
  }

  @include to(2) {
    margin: 0 5px;
  }
}

.small.small {
  @include from(3) {
    display: none;
  }
}

.large.large {
  @include to(2) {
    display: none;
  }
}

.title {
  line-height: 19px;
  text-align: center;

  @include from(3) {
    margin: 14px 0 8px;
    font-size: 17px;
  }

  @include to(2) {
    margin: 8px 0 6px;
    font-size: 16px;
  }
}

.description {
  margin: 0;
  text-align: center;

  @include to(2) {
    font-size: 13px;
    line-height: 15px;
  }
}
