@import 'breakpoints.scss';
@import 'colors.scss';
@import 'sizes.scss';
@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mobileBackgroundImage {
  width: 100vw;
  object-fit: cover;

  @include to(2) {
    min-height: 700px;
  }
}

.mobileBackgroundImage.dietbet {
  display: none;

  @include to(2) {
    display: block;
    background-repeat: no-repeat;
    background-position: top 40% left 0 !important;
    background-size: cover !important;
  }
}

.mobileBackgroundImage.stepbet {
  @include to(2) {
    min-height: 600px;
    max-height: 700px;
    background-position: center;
    background-size: cover;
  }

  @include from(3) {
    display: none;
  }
}

.backgroundImage {
  width: 100vw;
  object-fit: cover;
  object-position: top center;

  @include to(3) {
    height: calc(100vh - #{$headerHeightResponsive});
    max-height: 700px;
  }
}

.backgroundImage.dietbet,
.backgroundImage.stepbet {
  @include to(2) {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include to(2) {
    width: 100vw;
    padding: 20px 24px 60px;
    background-repeat: no-repeat;
    background-position: top left 0;
    background-size: contain;
  }

  @include from(2) {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
    background-repeat: no-repeat;
    background-position: top left 0;
    background-size: 100%;
  }
}

.header {
  color: $white;
  font-size: 15px;
  font-weight: 700;
  text-align: center;

  @include to(2) {
    padding: 0 18px;
    line-height: 1em;

    p,
    span {
      margin-bottom: 9px;
    }
  }

  @include from(2) {
    font-size: 25px;
  }
}

.body {
  @include markdownRemoveMargins;

  width: 100%;
  padding: 0 18px;
  color: $white;
  font-size: 15px;
  font-weight: 600;
  text-align: center;

  @include from(3) {
    width: 60%;
    margin-bottom: 32px;
    font-size: 21px;
  }
}

@each $theme, $themeColors in $themes {
  $secondaryColor: map-get($themeColors, 'secondary');

  .#{$theme}.backgroundImageContainer {
    background-color: $secondaryColor;
  }
}
