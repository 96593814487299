@import 'breakpoints.scss';

.container {
  @include from(3) {
    min-height: 700px;
  }
}

.background {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
