@import 'breakpoints.scss';

.plain {
  padding: 0 10px;
}

.padding {
  @include from(3) {
    padding: 100px 50px;
  }

  @include to(2) {
    padding: 100px 20px;
  }
}
