@import 'sizes.scss';
@import 'breakpoints.scss';

.container {
  flex-direction: column;
  padding: 50px 20px 30px;
}

.stats {
  z-index: 1;
  align-items: center;
  max-width: $maxWidth;
  margin: 0 auto;

  @include from(3) {
    justify-content: space-evenly;
  }

  @include to(2) {
    flex-direction: column;
  }

  > * {
    flex: 1 0;
    margin: 0 10px 30px;
  }
}

.title {
  z-index: 1;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
