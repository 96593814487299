@import 'utils.scss';
@import 'breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include to(1) {
    padding: 90px 20px;
  }

  @include from(2) {
    padding: 100px 0 55px;
  }

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}

.title {
  font-weight: normal;

  @include to(1) {
    margin-bottom: 50px;
    font-size: 28px;
  }

  @include from(2) {
    margin-bottom: 45px;
    font-size: 30px;
  }
}

.content {
  max-width: 55ch;
  font-size: 21px;
  text-align: center;

  @include to(1) {
    margin-bottom: 45px;
  }

  @include from(2) {
    margin-bottom: 45px;
    white-space: pre-line;
  }
}

.diagram {
  @include to(1) {
    width: 300px;
    margin-bottom: 45px;
  }

  @include from(2) {
    max-width: 500px;
    margin-bottom: 150px;
  }
}

.ways {
  @include to(1) {
    > * {
      margin-bottom: 35px;
    }
  }

  @include from(2) {
    // NOTE: fixes IE11 bug where content size is not respected.
    flex: 0 0 auto;
    padding: 0 22.5px;

    > * {
      margin: 0 22.5px 45px;
    }
  }
}

.exit {
  @include markdownRemoveMargins;

  text-align: center;

  @include to(1) {
    margin: 30px 0;
    font-size: 28px;
    white-space: pre-line;
  }

  @include from(2) {
    margin: 30px 0;
    font-size: 38px;
  }
}
