@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: $maxWidth;

  @include at(1) {
    padding: 25px;
  }

  @include from(2) {
    padding: 1em;
  }
}

.content {
  h2 {
    margin-bottom: 1em;
    font-size: 30px;
  }

  p {
    margin: 1em 0 3em;
  }
}

.button {
  width: 300px;
}
