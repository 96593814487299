@import 'breakpoints.scss';

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  user-select: none;

  @include from(3) {
    flex: 0 0 320px;
    min-height: 370px;
    margin: 0 15px 20px;
  }

  @include to(2) {
    width: 375px;
    margin-right: 25px;
    cursor: pointer;
  }
}

.image {
  width: 100%;
  margin-bottom: 25px;
  object-fit: contain;

  @include from(3) {
    height: 225px;
    border-radius: 5px;
  }

  @include to(2) {
    max-width: 450px;
    height: 264px;
  }
}

.quote {
  width: 70%;
  margin-top: 0;
}

.name {
  margin: auto 0 0;
  font-size: 19px;
  font-weight: 600;
}
