@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  text-align: center;

  @include from(3) {
    padding: 46px 30px 62px;
  }

  @include to(2) {
    padding: 30px 60px;
  }
}

$imageSize: 120px;

.image {
  width: $imageSize;
  height: $imageSize;
  margin: 0 auto;
  border-radius: 5px;
}

.imagePlaceholder {
  width: $imageSize;
  height: $imageSize;
  margin: 0 auto;

  @include from(3) {
    margin-bottom: 20px;
  }

  @include to(2) {
    margin-bottom: 15px;
  }
}

.title {
  font-weight: 600;

  @include from(3) {
    margin: 0 0 45px;
    font-size: 21px;
  }

  @include to(2) {
    margin: 0 0 30px;
    font-size: 15px;
  }
}

.content {
  @include from(3) {
    font-size: 22px;
    line-height: 25px;
  }

  @include to(2) {
    font-size: 17px;
    line-height: 22px;
  }
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');

  .#{$theme} .content {
    color: $secondary;
  }
}
