@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 60px;

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}

.quote {
  @include markdownRemoveMargins;
  @include quotations;

  margin-bottom: 60px;
  font-size: 32px;
  text-align: center;
}

.image {
  height: 125px;
  margin-bottom: 18px;
}

.name {
  margin-bottom: 18px;
  font-size: 21px;
  font-weight: 600;
}

// .location { }

.info {
  margin-bottom: 18px;
  font-style: italic;
}
