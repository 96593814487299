@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  margin: 16px 24px 0;

  @include to(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 450px;
  }

  @include from(4) {
    display: none;
  }
}

.containerFirst {
  margin-top: 0;
}

.cta.cta {
  width: 330px;
  margin-top: 10px;
  color: $white;
}

.ctaPrimary.ctaPrimary {
  background-color: $purple;

  &:hover {
    background-color: $white;
    color: $purple;
  }
}

.ctaSecondary.ctaSecondary {
  background-color: $coralLight;

  &:hover {
    background-color: $white;
    color: $coralLight;
  }
}

.paymentPlanLabel {
  margin-bottom: 10px;
  color: $white;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2em;
  text-align: center;
}
