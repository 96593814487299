@import 'breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 95px 37px;
}

.title {
  font-size: 34px;
  font-weight: 400;
  text-align: center;

  @include to(1) {
    margin-bottom: 100px;
  }

  @include from(2) {
    margin-bottom: 75px;
  }
}

.testimonials {
  justify-content: space-between;
  width: 100%;

  @include to(1) {
    > * {
      margin-bottom: 50px;
    }
  }

  @include from(2) {
    > * {
      flex: 1 0 300px;
      margin: 0 8px 16px;
    }
  }

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}
