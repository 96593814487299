@import 'breakpoints.scss';

.container {
  @include to(1) {
    padding: 65px 0 35px;
  }

  @include from(2) {
    padding: 70px 35px;
  }
}

.title {
  font-weight: 400;
  text-align: center;

  @include to(1) {
    margin-bottom: 30px;
    font-size: 18px;
  }

  @include from(2) {
    margin-bottom: 70px;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include to(1) {
    padding: 0 15px;
  }

  @include from(2) {
    padding: 0 25px;
  }

  > * {
    @include to(1) {
      height: 20px;
      margin: 0 15px 30px;
    }

    @include from(2) {
      height: 40px;
      margin: 0 20px 30px;
    }
  }
}
