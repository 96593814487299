@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  z-index: 1;
}

.link {
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.modal.modal {
  position: relative;
  max-width: 100%;
  max-height: 90%;
  border-radius: 0;
  background-color: $magnolia;
  box-shadow: -2px 2px 30px rgb(0 0 0 / 15%);

  @include to(2) {
    width: 95%;
  }

  @include from(3) {
    width: 80%;
  }
}

.modalContent {
  max-height: 75vh;
  margin-top: 7%;
  overflow-y: scroll;
}

.paragraphText {
  display: inline;
}

.paragraphContainer {
  display: inline;
}

.modalText {
  @include to(2) {
    font-size: 80%;
  }
}

.responsive {
  display: inline;
}

.closeButton {
  position: absolute;
  font-weight: 300;
}

.closeButton:hover {
  cursor: pointer;
}

.closeButtonContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
