@import 'utils.scss';

.container {
  text-align: center;
}

.image {
  height: 82px;
  margin-bottom: 16px;
}

.quote {
  @include markdownRemoveMargins;
  @include quotations;

  width: 300px;
}

.name {
  margin-top: 0;
  font-weight: 600;
}
