@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
}

.title {
  text-align: center;

  @include to(2) {
    display: none;
  }
}
